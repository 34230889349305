@mixin k-shadow {
  box-shadow: 0 0 8px 0 rgba($black, .1);
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin gradient-background($color-1, $color-2: false) {
  @if $color-2 {
    background: $color-1;
    background: linear-gradient(45deg, $color-1, $color-2);
  } @else {
    background: $color-1;
  }
}

@mixin secondary-button($color, $border-color, $hover-color) {
  border: 1px solid $border-color;
  background-color: $white;
  fill: $color;
  color: $color;

  &:hover, &:active {
    background-color: $border-color;
    color: $hover-color;
  }
}

@mixin priori-badge($color) {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 10px;
  background-color: $color;
  color: $white;
  font-weight: 700;
  line-height: 1;
  vertical-align: baseline;
}

@mixin hide-scrollbar {
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin auto-clear($col-type) {
  .col-#{$col-type}- {
    &1:nth-child(12n+1),
    &2:nth-child(6n+1),
    &3:nth-child(4n+1),
    &4:nth-child(3n+1),
    &6:nth-child(odd) {
      clear: left;
    }
  }
}

@mixin a-button($color, $background-color) {
  display: inline-block;
  width: 100%;
  height: 50px;
  padding: 15px 20px;
  border: 0;
  border-radius: 25px;
  outline: none;
  background-color: $background-color;
  color: $color;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  appearance: none;

  &:hover, &:active {
    background-color: lighten($background-color, 3%);
    color: $color;
    text-decoration: none;
  }

  &:focus {
    color: $color;
  }

  span {
    vertical-align: text-top;
  }

  .spinner {
    width: 20px;
    height: 20px;
  }
}

@mixin a-shadow {
  box-shadow: 0 5px 30px 0 rgba($black, .25);
}

@mixin a-button-small($color, $border-color, $hover-color, $hover-background) {
  display: inline-block;
  min-width: 100px;
  padding: 6px 14px;
  border: 1px solid $border-color;
  border-radius: 16px;
  outline: none;
  background-color: $white;
  color: $color;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  appearance: none;

  &:hover, &:active {
    border-color: $hover-background;
    background-color: $hover-background;
    color: $hover-color;
    text-decoration: none;

    .base-icon {
      fill: $hover-color;
    }
  }

  &:focus {
    color: $color;
    text-decoration: none;
  }

  .base-icon {
    fill: $color;
  }
}
