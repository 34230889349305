.pr-error {
  color: $error-red;
}

hr.medium {
  margin: 15px 0 20px;
}

.questionnaire {
  label {
    margin-bottom: 10px;
    color: $dark-blue;
    font-weight: 600;
  }

  .inline-help {
    font-size: 1.25em;
    font-weight: bold;
    line-height: 0;
  }

  .radio-check {
    margin: 5px 25px 5px 0;
    color: $body-text-color;
    font-weight: 400;

    input[type="checkbox"], input[type="radio"] {
      margin-right: 5px;
    }
  }
}

.question-list {
  counter-reset: section;

  .list-item.list-0::before {
    content: counter(section, decimal) ". ";
    counter-increment: section;
  }

  .list-item.list-1::before {
    content: counter(section, lower-alpha) ". ";
    counter-increment: section;
  }

  .list-item.list-2::before {
    content: counter(section, lower-roman) ". ";
    counter-increment: section;
  }

  .list-item.child-list::before {
    content: none;
  }
}

.checking-input {
  position: absolute;
  top: 0;
  opacity: 0;
}

.invisible-input {
  height: 0;
  opacity: 0;
}

.noresize {
  resize: none;
}

form.k-form, .a-form {
  .form-control {
    padding: 20px;
    border-color: $k-light-gray;
    box-shadow: none;
    color: $k-darker-gray;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.25;
    appearance: none;
    @include placeholder($k-dark-gray);

    &:focus {
      border-color: $k-blue;
      outline: none;
      box-shadow: none;
      @include placeholder(transparent);
    }
  }

  .has-error, .ng-invalid.ng-dirty, .ng-invalid.ng-touched, &.ng-submitted .ng-invalid {
    &:not(:focus), .form-control:not(:focus), select:focus {
      border-color: $error-red;
      color: $error-red;
    }
  }

  .input-group.datepicker-group .form-control:first-child.ng-invalid.ng-dirty, .input-group.datepicker-group .form-control:first-child.ng-invalid.ng-touched, &.ng-submitted .input-group.datepicker-group .form-control:first-child.ng-invalid {
    &:not(:focus) ~ .input-group-btn > .btn {
      border-color: $error-red;

      .button-icon {
        fill: $error-red;
      }
    }
  }

  .form-control[disabled] {
    background-color: $bg-color;
  }

  textarea.form-control {
    line-height: 1.5;
    resize: none;
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  select {
    height: 60px;
  }

  select.form-control {
    padding-right: 50px;
    background-color: $white;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 600'><path d='M531.203 179.127c-13.412-13.411-35.155-13.411-48.568 0L301.59 360.17 120.546 179.127c-13.412-13.411-35.157-13.411-48.568 0-13.413 13.411-13.413 35.158 0 48.569L267.792 423.51c18.798 18.796 49.28 18.781 68.057-.036l195.354-195.778c13.414-13.411 13.414-35.158 0-48.569z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 25px center;
    background-size: 14px 14px;
    font-weight: 600;

    &:focus {
      border-color: $k-light-gray;
    }

    &.ng-empty, &.empty {
      color: $k-dark-gray;
      font-weight: 400;
    }

    &.smaller {
      height: 40px;
      padding: 10px 20px;
    }
  }

  .check-option {
    position: relative;
    padding-right: 50px;
    padding-left: 20px;
    font-size: 13px;
    cursor: pointer;
    user-select: none;

    &.disabled {
      @include opacity(.5);

      &:hover {
        .check {
          border-color: $k-darker-gray;
        }
      }
    }

    &.compact {
      padding-right: 20px;

      &:last-child {
        padding-right: 0;
      }
    }

    &.single {
      width: 20px;
      height: 20px;
      padding: 0;

      .check {
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid $k-gray;
      }

      &:hover {
        .check {
          border-color: $k-blue;
        }
      }
    }

    &.vertical {
      display: block;
      padding-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;

      &:checked ~ .check {
        border-color: $k-blue;
        background-color: $k-blue;

        &::after {
          display: block;
        }
      }
    }

    .check {
      position: absolute;
      top: 3px;
      left: 0;
      width: 12px;
      height: 12px;
      border: 1px solid $k-dark-gray;

      &::after {
        content: "";
        display: none;
        position: absolute;
      }

      &:hover {
        border-color: $k-blue;
      }

      &.c-radio {
        border-radius: 50%;

        &::after {
          top: 3px;
          left: 3px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: $white;
        }
      }

      &.c-box {
        border-radius: $border-radius-small;

        &.disabled {
          background: $k-light-gray;

          &:hover {
            border-color: $k-dark-gray;
            cursor: not-allowed;
          }
        }
      }

      &.large {
        width: 20px;
        height: 20px;
      }
    }

    &.medium-large {
      padding-left: 25px;

      .check {
        top: 2px;
        width: 16px;
        height: 16px;

        &.c-radio::after {
          top: 4px;
          left: 4px;
          width: 6px;
          height: 6px;
        }
      }
    }

    .inline-help.required {
      color: $error-red;
      font-size: 14px;
      font-weight: 700;
      line-height: 0;
    }
  }

  label {
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;

    &.vertical-padded {
      margin: 0;
      padding-top: 13px;
      padding-bottom: 13px;
      line-height: 20px;

      @media (min-width: $screen-sm-min) {
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }

    &.radio-inline, &.checkbox-inline, &.no-margin {
      margin-bottom: 0;
    }

    .label-modifier {
      color: $k-dark-gray;
      font-style: italic;
      font-weight: 400;
    }

    &.bold-weight {
      font-weight: 700;
    }
  }

  .input-with-icon {
    position: relative;
    font-size: 13px;

    input[type="number"], input[type="text"] {
      padding-left: 25px;

      &.extra-padding {
        padding-left: 30px;
      }
    }

    .form-icon {
      position: absolute;
      top: 21px;
      left: 16px;
      color: $k-dark-gray;
    }
  }

  .inline-help.pr-error {
    color: $error-red;
    font-size: 12px;
    font-weight: 600;
  }

  .inline-help.required {
    color: $error-red;
    font-size: 14px;
    font-weight: 700;
    line-height: 0;
  }

  .has-error {
    color: $error-red;

    label {
      color: $error-red;
    }

    .form-control, .check-option .check {
      border-color: $error-red;
      color: $error-red;
    }
  }

  .error-explanation {
    margin-bottom: 30px;
    color: $error-red;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
  }

  .secondary-btn-blue.ng-invalid {
    &:hover, &:active {
      border-color: $k-blue;
      color: $white;
    }
  }

  .scrollable-checklist {
    height: 100px;
    padding: 10px;
    overflow: auto;
  }
}

.error-text {
  color: $error-red;
  font-size: 11px;
  font-weight: 600;

  .inline-help.pr-error {
    font-size: 11px;
  }
}

.a-form {
  .form-control {
    padding: 11px 16px;
    font-size: 16px;
    line-height: 22px;

    @media (min-width: $screen-sm-min) {
      font-size: 13px;
      line-height: 18px;
    }

    &::placeholder {
      font-size: 13px;
    }
  }

  label {
    margin-bottom: 5px;
  }

  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  select {
    height: 46px;

    @media (min-width: $screen-sm-min) {
      height: 42px;
    }
  }

  select.form-control {
    padding-right: 40px;
    background-position: right 14px center;
    font-weight: 400;

    @media (min-width: $screen-sm-min) {
      font-weight: 600;
    }

    &.ng-empty, &.empty {
      font-size: 13px;
    }
  }

  .input-with-icon {
    font-size: 16px;

    .form-icon {
      top: 11px;
      left: 10px;
    }

    @media (min-width: $screen-sm-min) {
      font-size: 13px;

      .form-icon {
        top: 12px;
      }
    }
  }

  .overlay-container {
    .input-overlay {
      top: 13px;

      @media (min-width: $screen-sm-min) {
        top: 11px;
      }
    }
  }

  .k-dropdown {
    .dropdown-toggle {
      position: relative;
      height: 46px;
      padding: 11px 40px 11px 16px;
      font-size: 16px;

      @media (min-width: $screen-sm-min) {
        height: 42px;
        font-size: 13px;
      }

      .caret-down, .caret-up {
        top: 16px;
        right: 16px;
        width: 14px;
        height: 14px;

        @media (min-width: $screen-sm-min) {
          top: 14px;
        }
      }
    }

    .dropdown-menu {
      > li, .menu-item {
        padding: 0 16px;

        .dropdown-row-inner {
          padding: 15px 0;

          &.disabled {
            cursor: not-allowed;
            color: $k-dark-gray;
          }
        }
      }
    }
  }

  .search-input {
    height: 46px;
    padding: 11px 40px 11px 16px;

    @media (min-width: $screen-sm-min) {
      height: 42px;
    }
  }
}

.nested-form {
  max-width: 710px;

  .nested-form-item {
    padding: 20px;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-large;

    a {
      color: $k-darker-gray;

      &.disabled {
        color: $k-dark-gray;
      }
    }

    &.active {
      border: 1px solid $k-blue;
    }

    .nested-form-icon {
      margin-top: -4px;
      margin-right: 5px;
      fill: $dark-yellow;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.file-upload {
  input[type="file"] {
    display: none;
  }

  .upload-button {
    display: inline-block;
    padding-top: 20px;
    font-weight: 700;
  }

  .file-name {
    text-align: left;
  }
}

.form-section-content {
  max-width: 740px;

  @media (min-width: $screen-sm-min) {
    padding-left: 30px;
  }

  &.no-indentation {
    padding: 0;
  }
}

.document-list {
  display: inline-block;
  font-size: 13px;
}

.document-list-item {
  display: inline-block;
  position: relative;
  max-width: 200px;
  margin-top: 5px;
  padding: 10px 20px;
  border: 1px solid $k-light-gray;
  border-radius: 23px;
  font-weight: 600;

  &.removable {
    padding: 10px 40px 10px 20px;

    &.selected {
      border: 1px solid $error-red;
    }

    .file-name {
      max-width: 140px;
    }

    .delete-button {
      position: absolute;
      top: 13px;
      right: 20px;
      cursor: pointer;

      &:hover {
        fill: $error-red;
      }
    }
  }

  &.smaller {
    margin-top: 0;

    .file-name {
      line-height: normal;
    }
  }

  .file-name {
    line-height: 24px;
    @include text-ellipsis;

    > a {
      color: $k-darker-gray;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &:hover {
      cursor: default;
    }
  }
}

.bottom-section {
  border-top: 1px solid $k-light-gray;
}

.ui-datepicker {
  padding: 0;
  border: 0;
  border-radius: $border-radius-large;
  background: $white;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  @include k-shadow;

  .ui-datepicker-header {
    position: relative;
    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
    background-color: $k-blue;
    color: $white;
    font-weight: 600;

    .ui-datepicker-prev {
      position: absolute;
      top: 2px;
      left: 2px;
      padding: 18px 26px;
      color: $white;
    }

    .ui-datepicker-next {
      position: absolute;
      top: 2px;
      right: 2px;
      padding: 18px 26px;
      color: $white;
    }

    .ui-datepicker-title {
      padding: 18px 26px;
      line-height: 1.8em;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .ui-datepicker-calendar {
    color: $k-darker-gray;
  }

  .ui-datepicker-unselectable {
    color: $k-gray;
  }

  a.ui-state-default {
    color: $k-darker-gray;

    &.ui-priority-secondary {
      color: $k-dark-gray;
    }

    &:hover {
      color: $k-blue;
      text-decoration: underline;
    }
  }

  .ui-datepicker-today {
    a {
      color: $k-blue;
      font-weight: 600;
    }
  }

  tr > th, td {
    padding: 10px;
    text-align: center;
  }
}

.kenny-styles {
  .uib-datepicker-popup.dropdown-menu {
    padding: 0;
    border: 0;
    border-radius: $border-radius-large;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    @include k-shadow;

    .uib-daypicker, .uib-monthpicker, .uib-yearpicker {
      thead > tr:first-child {
        background-color: $k-blue;
        color: $white;

        th:first-child {
          border-top-left-radius: $border-radius-large;
        }

        th:last-child {
          border-top-right-radius: $border-radius-large;
        }
      }

      th {
        padding: 10px;
        font-weight: 600;
      }

      small {
        font-size: 13px;
      }

      strong {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
      }

      .btn {
        margin: 0;
      }

      .uib-left, .uib-title, .uib-right {
        border: 0;
        background-color: transparent;
        color: $white;
        text-transform: uppercase;

        &:active, &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .uib-day button, .uib-month button, .uib-year button {
        border: 0;
        background-color: transparent;
        box-shadow: none;
        color: $k-darker-gray;
      }

      .uib-day button {
        span {
          display: inline-block;
          width: 30px;
          height: 30px;
          padding: 8px;
        }

        &:hover span, &.btn-info span {
          border-radius: 50%;
          background-color: $k-blue;
          color: $white;
        }
      }

      .uib-month button, .uib-year button {
        span {
          display: inline-block;
          padding: 5px 10px;
        }

        &:hover span, &.btn-info span {
          border-radius: $border-radius-base;
          background-color: $k-blue;
          color: $white;
        }
      }

      .text-info {
        color: $k-blue;
        font-weight: 700;
      }

      .text-muted {
        color: $k-gray;
      }
    }
  }

  .a-form {
    .input-group.datepicker-group {
      .input-group-btn:last-child > .btn {
        height: 46px;
        padding: 0 14px 0 1px !important;
        border-radius: 0 $border-radius-base $border-radius-base 0;

        @media (min-width: $screen-sm-min) {
          height: 42px;
        }
      }
    }
  }

  .input-group.datepicker-group {
    .form-control:first-child {
      border-right: 0;
      font-weight: 600;

      &:focus ~ .input-group-btn > .btn {
        border-color: $k-blue;

        .button-icon {
          fill: $k-blue;
        }
      }

      &:disabled {
        background-color: $bg-color;

        ~ .input-group-btn > .btn {
          background-color: $bg-color;
          cursor: not-allowed;
        }
      }

      &::placeholder {
        font-weight: 400;
      }
    }

    .input-group-btn:last-child > .btn {
      height: 60px;
      padding: 0 20px 0 1px !important;
      transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
      border-left: 0;
      border-color: $k-light-gray;
      background-color: $white;

      &:active, &:focus {
        outline: none;
        box-shadow: none;
      }

      .button-icon {
        width: 20px;
        height: 20px;
        margin: 0;
        fill: $k-darker-gray;
      }
    }
  }
}

.show-filter-btn {
  display: inline-block;

  @media (min-width: $screen-sm-min) {
    display: none;
  }
}

.filter-container.ng-hide {
  @media (min-width: $screen-sm-min) {
    display: block !important;
  }
}

.filter-label {
  display: block;
  margin-bottom: 10px;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;

  @media (min-width: $screen-md-min) {
    display: inline;
    margin: 0 8px 0 0;
  }

  &.sort-label {
    margin-top: 30px;

    @media (min-width: $screen-sm-min) {
      margin-top: 0;
    }
  }
}

.filter-dropdown {
  display: inline-block;
  width: 100%;
  vertical-align: middle;

  @media (min-width: $screen-md-min) {
    width: auto;
  }

  .dropdown-toggle {
    width: 100%;
    min-width: 160px;
    height: 60px;
    margin: 0;
    padding: 6px 30px !important;
    border: 0;
    border-radius: $border-radius-large;
    background-color: $bg-color;
    color: $k-darker-gray;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    text-align: left;
    @include k-shadow;

    @media (min-width: $screen-md-min) {
      width: auto;
    }

    .caret-down, .caret-up {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin-left: 10px;
      float: right;
      vertical-align: middle;
    }

    .caret-up {
      display: none;
    }
  }

  .dropdown-menu {
    width: 100%;
    min-width: 160px;
    margin: 0;
    padding: 0 15px;
    border: 0;
    border-radius: 0 0 $border-radius-large $border-radius-large;
    font-size: 13px;
    font-weight: 400;
    @include k-shadow;

    @media (min-width: $screen-md-min) {
      width: auto;
    }

    > li {
      border-bottom: 1px solid $k-light-gray;

      &:last-child {
        border-bottom: 0;
      }

      > a {
        padding: 15px;
        color: $k-darker-gray;

        &:hover {
          background-color: $white;
          color: $k-blue;
        }
      }
    }
  }

  &.open .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $k-blue;
    box-shadow: none;
    color: $white;

    .caret-down {
      display: none;
    }

    .caret-up {
      display: inline-block;
      fill: $white;
    }
  }

  &.send-invoice-dropdown {
    .dropdown-toggle, .dropdown-menu {
      min-width: 230px;
    }
  }
}

.k-dropdown {
  display: block;
  width: 100%;

  .dropdown-toggle {
    width: 100%;
    height: 60px;
    padding: 15px 60px 15px 20px;
    transition: background-color ease-in-out .15s;
    border: 1px solid $k-light-gray;
    border-radius: $border-radius-base;
    background-color: $white;
    color: $k-darker-gray;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    box-shadow: none;
    @include text-ellipsis;

    &.placeholder {
      color: $k-dark-gray;
    }

    &.invalid {
      border-color: $error-red;
    }

    &.disabled {
      @include opacity(.5);
    }

    .caret-down, .caret-up {
      position: absolute;
      top: 23px;
      right: 25px;
      width: 14px;
      height: 14px;
      fill: $k-darker-gray;
    }

    .caret-up {
      display: none;
    }

    &:disabled {
      background-color: $bg-color;

      .caret-down {
        display: none;
      }
    }
  }

  &.open .dropdown-toggle {
    background-color: $bg-color;
    box-shadow: none;

    .caret-down {
      display: none;
    }

    .caret-up {
      display: block;
    }
  }

  &.invalid .dropdown-toggle {
    border-color: $error-red;
  }

  .dropdown-menu {
    width: 100%;
    margin: 10px 0 0;
    padding: 0;
    border: 0;
    border-radius: $border-radius-large;
    font-size: 13px;
    font-weight: 400;
    @include k-shadow;

    &.with-scrollable {
      &.scrolled-up::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
        border-bottom-right-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
        background: linear-gradient(180deg, rgba($white, 0) 0%, $white 100%);
        pointer-events: none;
      }

      &.scrolled-down::before {
        content: "";
        position: absolute;
        top: 0;
        width: 100%;
        height: 60px;
        border-top-left-radius: $border-radius-large;
        border-top-right-radius: $border-radius-large;
        background: linear-gradient(180deg, $white 0%, rgba($white, 0) 100%);
        pointer-events: none;
      }
    }

    .menu-item-container {
      max-height: 280px;
      overflow-y: auto;
      @include hide-scrollbar;
    }

    > li, .menu-item {
      padding: 0 20px;
      color: $k-darker-gray;

      &:first-child {
        border-radius: $border-radius-large $border-radius-large 0 0;
      }

      &:last-child {
        border-radius: 0 0 $border-radius-large $border-radius-large;

        .dropdown-row-inner {
          border-bottom: 0;
        }
      }

      &:hover {
        background-color: $bg-color;
        cursor: pointer;
      }

      .dropdown-row-inner {
        width: 100%;
        padding: 20px 0;
        border-bottom: 1px solid $k-light-gray;

        &.disabled {
          color: $k-dark-gray;
          cursor: not-allowed;
        }
      }
    }
  }
}

ul.ui-autocomplete {
  display: none;
  position: absolute;
  z-index: 1000;
  left: 0;
  min-width: 160px;
  max-height: 310px;
  margin: 10px 0 0;
  padding: 0;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
  list-style-type: none;
  border: 0;
  border-radius: $border-radius-large;
  background-color: $white;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  @include k-shadow;

  &:first-child {
    border-radius: $border-radius-large $border-radius-large 0 0;
  }

  &:last-child {
    border-radius: 0 0 $border-radius-large $border-radius-large;
  }

  li.ui-menu-item {
    padding: 10px;
    border-bottom: 1px solid $k-light-gray;

    &:hover {
      background-color: $bg-color;
      cursor: pointer;
    }

    > a {
      width: 100%;
      color: $k-darker-gray;
      text-decoration: none;

      &.active, &.active > a, &.active > a:hover, &.active > a:focus {
        background-color: $bg-color;
        color: $k-darker-gray;
      }

      > strong {
        color: $k-blue;
      }
    }
  }
}

.typeahead-container {
  position: relative;
}

[uib-typeahead-popup].dropdown-menu, .open > .dropdown-menu {
  width: 100%;
  margin: 10px 0 0;
  padding: 0;
  border: 0;
  border-radius: $border-radius-large;
  font-size: 13px;
  font-weight: 400;
  @include k-shadow;

  > li {
    padding: 0 20px;
    color: $k-darker-gray;

    &:first-child {
      border-radius: $border-radius-large $border-radius-large 0 0;
    }

    &:last-child {
      border-radius: 0 0 $border-radius-large $border-radius-large;
    }

    &:hover {
      background-color: $bg-color;
      cursor: pointer;
    }

    > a {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid $k-light-gray;
      @include text-ellipsis;

      strong, b {
        color: $k-blue;
      }

      &:hover {
        background-color: $bg-color;
      }
    }

    &.active, &.active > a, &.active > a:hover, &.active > a:focus {
      background-color: $bg-color;
      color: $k-darker-gray;
    }
  }
}

.uiv-datepicker {
  width: 100%;

  .dropdown-menu {
    margin-left: 0;
    padding: 0;
    border: 0;
    border-radius: $border-radius-large;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    width: auto;
    @include k-shadow;

    @media (min-width: $screen-sm-min) {
      margin-left: -200px;
    }

    @media (min-width: $screen-md-min) {
      margin-left: -100px;
    }

    li {
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }

    .uiv-datepicker-month,
    .uiv-datepicker-year {
      .btn-block {
        background-color: $white;

        span {
          display: inline-block;
          padding: 5px 10px;
        }

        &:hover span, &.btn-primary span {
          border-radius: $border-radius-base;
          background-color: $k-blue;
          color: $white;
        }
      }
    }

    table {
      thead tr {
        &:first-child {
          background-color: $k-blue;
          color: $white;

          .btn-block {
            background-color: transparent;
          }

          td:first-child {
            border-top-left-radius: $border-radius-large;
          }

          td:last-child {
            border-top-right-radius: $border-radius-large;
          }
        }

        td {
          padding: 10px;
          font-weight: 600;
        }
      }

      small {
        font-size: 13px;
      }

      strong {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
      }

      .btn {
        margin: 0;
      }

      .uiv-datepicker-pager-prev,
      .uiv-datepicker-title,
      .uiv-datepicker-pager-next {
        border: 0;
        background-color: transparent;
        color: $white;
        text-transform: uppercase;

        &:active, &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      .uib-day {
        border: 0;
        background-color: transparent;
        box-shadow: none;
        color: $k-darker-gray;

        span {
          display: inline-block;
          width: 30px;
          height: 30px;
          padding: 8px;
        }

        &:hover span, &.btn-primary span {
          border-radius: 50%;
          background-color: $k-blue;
          color: $white;
        }

        &.btn-info {
          background-color: transparent;
          color: $k-blue;
          font-weight: 700;
        }
      }

      .text-muted {
        color: $k-gray;
      }
    }
  }
}

.search-input {
  width: 100%;
  height: 60px;
  padding: 20px 40px 20px 30px;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 0 18 18'><path d='M11.455 16.185l-1.763-2.801a1.75 1.75 0 0 1-.186-.518l-.084-.666 1.073-.699.56.357c.112.072.313.274.384.388l1.764 2.8-1.748 1.139zm-6.188-5.313a4.547 4.547 0 0 1-2.873-2.036 4.69 4.69 0 0 1-.616-3.506A4.623 4.623 0 0 1 3.78 2.408a4.49 4.49 0 0 1 3.447-.627A4.546 4.546 0 0 1 10.1 3.817a4.69 4.69 0 0 1 .616 3.506 4.624 4.624 0 0 1-2.001 2.923 4.482 4.482 0 0 1-3.448.626zm9.482 3.588l-1.951-3.1a3.258 3.258 0 0 0-.886-.892l-.604-.385c-.021-.014-.043-.025-.064-.037a6.33 6.33 0 0 0 1.048-2.371 6.342 6.342 0 0 0-.833-4.742A6.15 6.15 0 0 0 7.573.178a6.061 6.061 0 0 0-4.663.848A6.253 6.253 0 0 0 .203 4.978a6.343 6.343 0 0 0 .833 4.743 6.15 6.15 0 0 0 5.224 2.902c.529 0 1.053-.07 1.563-.207l.084.66c.047.374.227.875.426 1.193l1.951 3.099c.242.383.65.592 1.067.592.234 0 .47-.065.68-.202l2.335-1.52c.284-.186.48-.473.553-.809.072-.336.012-.68-.17-.969z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px 16px;
  box-shadow: none;
  color: $k-darker-gray;
  font-size: 13px;
  font-weight: 600;
  appearance: none;
  @include placeholder($k-darker-gray);

  &:focus {
    border: 1px solid $k-blue;
    outline: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-1.5 0 18 18'><path style='fill:%231484c9;' d='M11.455 16.185l-1.763-2.801a1.75 1.75 0 0 1-.186-.518l-.084-.666 1.073-.699.56.357c.112.072.313.274.384.388l1.764 2.8-1.748 1.139zm-6.188-5.313a4.547 4.547 0 0 1-2.873-2.036 4.69 4.69 0 0 1-.616-3.506A4.623 4.623 0 0 1 3.78 2.408a4.49 4.49 0 0 1 3.447-.627A4.546 4.546 0 0 1 10.1 3.817a4.69 4.69 0 0 1 .616 3.506 4.624 4.624 0 0 1-2.001 2.923 4.482 4.482 0 0 1-3.448.626zm9.482 3.588l-1.951-3.1a3.258 3.258 0 0 0-.886-.892l-.604-.385c-.021-.014-.043-.025-.064-.037a6.33 6.33 0 0 0 1.048-2.371 6.342 6.342 0 0 0-.833-4.742A6.15 6.15 0 0 0 7.573.178a6.061 6.061 0 0 0-4.663.848A6.253 6.253 0 0 0 .203 4.978a6.343 6.343 0 0 0 .833 4.743 6.15 6.15 0 0 0 5.224 2.902c.529 0 1.053-.07 1.563-.207l.084.66c.047.374.227.875.426 1.193l1.951 3.099c.242.383.65.592 1.067.592.234 0 .47-.065.68-.202l2.335-1.52c.284-.186.48-.473.553-.809.072-.336.012-.68-.17-.969z'/></svg>");
    background-repeat: no-repeat;
    background-position: right 18px center;
    background-size: 16px 16px;
    box-shadow: none;
    @include placeholder(transparent);
  }
}

.numbered-label {
  width: 100%;

  @media (min-width: $screen-sm-min) {
    .label-number {
      display: block;
      width: 30px;
      float: left;
    }

    .label-content {
      display: block;
      width: calc(100% - 30px);
      float: left;
    }
  }
}

.edit-links {
  font-weight: 600;
  text-align: left;

  @media (min-width: $screen-sm-min) {
    text-align: right;
  }

  > svg-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .delete {
    color: $error-red;
  }

  > span:not(:first-child) {
    margin-left: 10px;
  }

  > span {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    &.active {
      color: $k-blue;
    }

    &.delete {
      color: $error-red;
    }

    &.disabled {
      color: $k-dark-gray;
      font-weight: 400;

      &:hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }
}

.search-box {
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;
}

.search-icon {
  position: absolute;
  top: 31px;
  left: 40px;

  svg-icon {
    fill: $k-dark-gray;
  }
}

.search-box-input-container {
  position: relative;
  padding: 0;
}

.k-form .search-box-input-container {
  input[type="text"] {
    height: 45px;
    padding: 10px 10px 10px 55px;
  }

  .search-icon {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.a-form .search-box-input-container {
  input[type="text"] {
    padding-left: 40px;
  }

  .search-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.keyword-search-container {
  position: relative;

  .form-control.keyword-search-input {
    padding-right: 60px;

    &:focus ~ .keyword-search-icon {
      fill: $k-blue;
    }
  }

  .keyword-search-icon {
    position: absolute;
    top: 21px;
    right: 21px;

    &.less-padded {
      top: 8px;
      right: 8px;
    }
  }
}

.a-form .keyword-search-container {
  .form-control.keyword-search-input {
    padding-right: 40px;
  }

  .keyword-search-icon {
    top: 12px;
    right: 15px;

    &.clear-icon {
      top: 11px;

      .base-icon {
        display: block;
        width: 10px;
        height: 10px;
      }

      &:hover svg {
        fill: $k-red;
      }
    }
  }
}

.box-select {
  padding: 20px;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-base;
  cursor: pointer;

  &:hover {
    border: 1px solid $k-blue;
    background-color: rgba($k-blue, .05);
  }

  &.selected {
    border: 1px solid $k-blue;
    background-color: rgba($k-blue, .05);
  }
}
