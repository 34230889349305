@import "stylesheets/globals/colors";

$k-blue: #1484c9;
$k-purple: #af2e8d;
$k-dark-purple: #8c00e5;
$alt-dark-purple: #7720A3;
$wave-end-purple: #9213b2;
$k-green: #3daa2d;
$k-red: #d13737;
$k-darker-gray: #333;
$k-dark-gray: #999;
$k-gray: #ccc;
$k-light-gray: #eee;
$k-lighter-gray: #f6f6f6;
$high-match-score-blue: #2331BD;

$dark-red: #a93327;
$red: #d04133;
$error-red: $k-red;
$light-red: #d55448;
$error-background: #f9e9e8;
$lightest-red: #ffe6e6;

$green-shadow: #2a891c;
$green-hover: #61a258;
$green: #73bd69;
$gradient-green: #81c17d;
$k-light-green-bg: #eef7ec;
$k-green-background: #f1f8f0;

$night-blue: #0a1370;
$alt-dark-blue: #15294c;
$dark-blue: #1a335e;
$blue: #6798c6;
$gradient-blue: #74a1cd;
$k-light-blue-bg: #f0f5f9;
$blue-hover: #e4f2fa;

$k-yellow-shadow: #cea643;
$k-yellow-hover: #dbb047;
$k-yellow-dark: #ffa21f;
$k-yellow: #e5b94b;
$dark-yellow: #eec34e;
$k-light-yellow-bg: #fbf5e8;
$p-yellow: #f7b500;
$p-yellow-bg: rgba($p-yellow, .14);

$k-orange: #e38863;
$orange: #f1a169;

$k-light-purple: #fcf7fb;
$light-purple: #f4e7f7;

$black: #000;
$dark-grey-bg: #303030;
$grey: $k-darker-gray;
$grey-6: #444;
$darker-gray: #666;
$pill-gray: #767676;
$grey-5: #777;
$grey-4: #bbb;
$gray-input-border: #c9c9c9;
$medium-gray: #ddd;
$grey-3: #dfdfdf;
$almost-white: #e7e7e7;
$grey-2: #efefef;
$light-gray-bg: #f2f2f2;
$grey-1: #f5f5f5;
$bg-color: #f9f9f9;
$gradient-gray: #fdfdfd;
$white: #fff;

$body-text-color: $k-darker-gray;

$border-radius-base: 4px;
$border-radius-large: 6px;
$border-radius-small: 2px;

$nav-height: 80px;
$footer-height: 100px;
$sidenav-width: 250px;

// Media queries breakpoints
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

// Scout Tag colors

$tag-blue: #0a6fc9;
$tag-blue-bg: #e9f2fa;
$tag-gray: #333;
$tag-gray-bg: #e5e5e5;
$tag-green: #025a01;
$tag-green-bg: #ded;
$tag-purple: #4e0a60;
$tag-purple-bg: #d015f836;
$tag-red: #6e1b1b;
$tag-red-bg: #ff9696c4;
$tag-yellow: #453e2c;
$tag-yellow-bg: #fde4a0;
