$throbber-color: $k-light-gray !default;
$throbber-highlight-color: $k-darker-gray !default;
$throbber-height: 18px !default;
$throbber-segment-width: 10px !default;
$throbber-spacing: 8px !default;

@keyframes throbber {
  0% { background: $throbber-color; }
  10% { background: $throbber-highlight-color; }
  40% { background: $throbber-color; }
}

// :not(:required) hides these rules from IE9 and below
.throbber:not(:required) {
  display: inline-block;
  position: relative;
  width: $throbber-segment-width;
  height: $throbber-height;
  margin: 0 ($throbber-segment-width + $throbber-spacing);
  animation: throbber 1500ms 300ms infinite ease-out;
  border-radius: 2px;
  background: $throbber-color;
  text-indent: -9999px;

  &::before, &::after {
    content: "\x200B";
    display: inline-block;
    position: absolute;
    top: 0;
    width: $throbber-segment-width;
    height: $throbber-height;
    border-radius: 2px;
    background: $throbber-color;
  }

  &::before {
    left: -($throbber-segment-width + $throbber-spacing);
    animation: throbber 1500ms 150ms infinite ease-out;
  }

  &::after {
    right: -($throbber-segment-width + $throbber-spacing);
    animation: throbber 1500ms 450ms infinite ease-out;
  }
}

.loading-modal {
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1;
}

.padded .loading-modal {
  padding: 15px 0;
}

.loading-wrapper.splash-page > .loading-modal {
  height: calc(100vh - 100px);
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spinner {
  to { transform: rotate(360deg); }
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  animation: spinner .8s linear infinite;
  border: 2px solid rgba($white, .4);
  border-radius: 50%;
  border-top-color: $white;
  vertical-align: text-top;

  &.dark {
    border-color: $k-light-gray;
    border-top-color: $k-darker-gray;
  }

  &.darker {
    border-color: $k-gray;
    border-top-color: $k-darker-gray;
  }

  &.larger {
    width: 18px;
    height: 18px;
  }
}
