.rzslider {
  height: 2px;

  .rz-bar-wrapper {
    height: 24px;
    margin-top: -12px;
    padding-top: 12px;
  }

  .rz-bar {
    height: 2px;
    border-radius: 1px;
  }

  .rz-selection {
    border-radius: 1px;
    background: $k-blue;
  }

  .rz-pointer {
    z-index: 2;
    top: -8px;
    width: 16px;
    height: 16px;
    border-radius: 12px;
    background-color: $k-blue;

    &::after {
      top: 4px;
      left: 4px;
      background-color: $k-blue;
    }

    &.rz-active {
      outline: none;

      &::after {
        background-color: $k-blue;
      }
    }

    &:hover {
      &::after {
        background-color: $k-blue;
      }
    }
  }

  .rz-bubble {
    bottom: 12px;
    padding: 8px 3px;

    &.rz-model-high {
      padding: 0;
      font-size: 11px;
    }

    &.rz-model-value {
      padding: 0;
      font-size: 11px;
    }

    &.rz-model-range {
      padding: 0;
      font-size: 11px;
    }
  }

  .rz-ticks {
    top: -1px;
  }

  .rz-tick {
    width: 2px;
    height: 4px;
    margin-left: 11px;

    &.rz-selected {
      background: $k-blue;
    }
  }

  &.rz-vertical {
    width: 2px;

    .rz-bar-wrapper {
      margin: 0 0 0 -12px;
      padding: 0 0 0 12px;
    }

    .rz-bar {
      width: 2px;
    }

    .rz-pointer {
      left: -11px !important;
    }

    .rz-ticks {
      left: -1px;
    }

    .rz-tick {
      margin-top: 11px;
    }
  }
}
