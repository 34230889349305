.max-container {
  max-width: 1440px;
}

.vertical-bar {
  display: inline-block;
  width: 2px;
  height: 40px;
  border: 1px solid $k-gray;

  &.inverted-color {
    border-color: rgba($white, .3);
  }
}

.padded-section {
  padding-top: 50px;
  padding-bottom: 50px;

  @media (min-width: $screen-sm-min) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  h1, h2 {
    margin-top: 0;
  }
}

.new-close-modal {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $k-dark-gray;
  box-shadow: 0 2px 4px 0 rgba($black, .1);
  line-height: 14px;
  text-align: center;

  @media (min-width: $screen-sm-min) {
    line-height: 10px;
  }

  &:hover {
    background-color: $k-red;
    cursor: pointer;
  }

  &.full-screen {
    top: 10px;
    right: 10px;

    @media (min-width: $screen-sm-min) {
      top: -10px;
      right: -10px;
    }
  }

  .base-icon {
    display: inline-block;
    width: 6px;
    height: 6px;
    fill: $white;
    line-height: 6px;
  }
}

.error_message {
  color: $error-red;
  font-size: 11px;
  font-weight: 600;
}

.modal {
  margin-top: 60px;

  &.no-margin {
    margin-top: 0;
  }
}

.errors {
  margin-bottom: 40px;
  color: $error-red;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;

  &.no-margin {
    margin-bottom: 0;
  }
}

.field_with_errors {
  color: $error-red;

  input[type="email"] {
    border-color: $error-red;
  }

  + .inline-help {
    color: $error-red;
  }
}
