.upper-alpha-count-container {
  counter-reset: upper-alpha-counter;

  .upper-alpha-increment {
    counter-increment: upper-alpha-counter;

    &::before {
      content: counter(upper-alpha-counter, upper-alpha) ".";
      display: inline-block;
      width: 28px;
    }
  }
}