$brand-primary: #1484c9;
$gray-light: #999;
$grid-float-breakpoint: 992px !default;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/" !default;
$modal-inner-padding: 0;
$pagination-border: #eee;
$pagination-disabled-border: #eee;
$pagination-hover-bg: #f9f9f9;
$pagination-hover-border: #eee;
$table-border-color: #eee;

$font-family-sans-serif: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
