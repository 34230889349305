.sign-up-message {
  color: $k-dark-gray;
  font-size: 13px;
  font-weight: 400;
}

.auth-container {
  min-height: calc(100vh - 80px);

  .checkbox {
    margin-bottom: 0;

    label {
      padding-left: 25px;
    }

    input[type="checkbox"] {
      margin: 2px 0 0;
      margin-left: -25px;
    }
  }

  .agree-terms-error {
    margin-left: 25px;
  }
}

.success-message .text-success {
  color: $body-text-color;
  font-weight: 400;
}

body.signed-out-gradient {
  background-image: url("../src/images/sign-in-bg.svg"), linear-gradient(67deg, $purple-5 -10.84%, $night-blue 50.69%, $blue-4 106.9%);
  background-size: cover;
  height: 100vh;

  .brand-icon {
    display: block;
    width: 140px;
    height: 26px;
    margin: 0 auto;
  }

  form {
    label {
      margin-bottom: 5px;
    }

    .primary-btn-blue {
      border-color: $blue-4;
      background-color: $blue-4;

      &:hover, &:active {
        border-color: lighten($blue-4, 3%);
        background-color: lighten($blue-4, 3%);
      }

      .base-icon, .base-icon svg {
        width: 16px;
        height: 16px;
        fill: $white;
        vertical-align: text-top;
      }
    }
  }

  .warning-icon {
    background: #faebeb;
    border-radius: 100%;
    width: 70px;
    display: inline-block;
    height: 70px;
    padding-top: 20px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
