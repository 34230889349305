.kenny-styles .table {
  th, td {
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid $k-light-gray;
    font-size: 13px;
    font-weight: 400;

    &:last-child {
      padding-right: 0;
    }
  }

  th {
    color: $k-dark-gray;
    font-size: 11px;
    font-weight: 600;
    vertical-align: middle;
  }

  td {
    height: 70px;
    max-height: 70px;
    vertical-align: middle;

    a.actions-link {
      color: $k-dark-gray;
      font-size: 11px;
      font-weight: 600;

      &:hover {
        color: $k-darker-gray;
      }

      &.canceling {
        color: $k-red;

        &:hover {
          color: darken($k-red, 10%);
        }
      }
    }
  }

  tbody > tr:last-child td {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &.full-height tbody > tr:last-child td {
    padding-bottom: 10px;
  }

  &.fixed-table {
    @media (min-width: $screen-sm-min) {
      width: 100%;
      table-layout: fixed;

      th, td {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ellipsis-cell {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.auto-height td {
    height: auto;
  }

  &.no-margin {
    margin: 0;
  }

  .number {
    text-align: right;
  }
}

.table-responsive {
  @media (max-width: $screen-xs-max) {
    margin: 0;
    padding: 10px;

    .table th:last-child, .table td:last-child {
      padding-right: 10px;
    }
  }
}

.kenny-table {
  width: 100%;
  border-collapse: separate;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-base;
  font-size: 13px;

  th, td {
    padding: 10px 20px;
    border-right: 1px solid $k-light-gray;
    border-bottom: 1px solid $k-light-gray;
    vertical-align: middle;

    &:last-child {
      border-right: 0;
    }
  }

  th {
    background-color: $bg-color;
    font-weight: 600;

    &:first-child {
      border-top-left-radius: $border-radius-base;
    }

    &:last-child {
      border-top-right-radius: $border-radius-base;
    }
  }

  td {
    font-weight: 400;
  }

  tbody tr:last-child td {
    border-bottom: 0;

    &:first-child {
      border-bottom-left-radius: $border-radius-base;
    }

    &:last-child {
      border-bottom-right-radius: $border-radius-base;
    }
  }
}
