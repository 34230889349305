.modal-backdrop.in {
  @include opacity(.7);
}

@media (min-width: $screen-sm-min) {
  .modal-dialog {
    margin: 40px auto;
  }
}

.close-modal {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $k-dark-gray;
  box-shadow: 0 2px 4px 0 rgba($black, .1);
  line-height: 12px;
  text-align: center;

  @media (min-width: $screen-sm-min) {
    line-height: 10px;
  }

  &:hover {
    background-color: $k-red;
    cursor: pointer;
  }

  &.full-screen {
    top: 8px;
    right: 8px;

    @media (min-width: $screen-sm-min) {
      top: -10px;
      right: -10px;
    }
  }

  .base-icon {
    display: inline-block;
    width: 6px;
    height: 6px;
    fill: $white;
    line-height: 6px;
  }

  &.vertical-center {
    justify-content: center;
    min-height: unset;

    .base-icon, svg {
      display: block;
    }
  }
}

.notice-modal {
  .notice-header {
    height: 60px;
    padding: 23px 30px;
    border-radius: $border-radius-large $border-radius-large 0 0;
    background-color: $bg-color;
    color: $k-darker-gray;
    font-size: 13px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    @include text-ellipsis;

    &.fluid-header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      min-height: 60px;
    }

    &.error-notice {
      background-color: $error-background;
      color: $error-red;
    }
  }

  .large-headline {
    font-size: 24px;
  }

  .medium-headline {
    font-size: 20px;
  }

  .notice-text {
    padding: 20px 30px 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;

    &.less-padded {
      padding-top: 0;
    }
  }

  .notice-regular-text {
    padding: 30px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;

    &.extra-padded {
      @media (min-width: $screen-sm-min) {
        padding: 60px;
      }
    }

    &.less-padded {
      padding: 20px 30px;
    }
  }

  .notice-note {
    padding: 0 30px 30px;
    font-size: 13px;
    line-height: 1.5;
    text-align: center;

    &.notice-note-red {
      color: $k-red;
      text-align: left;
    }
  }

  .notice-action-container {
    padding: 0 30px;
  }

  .notice-actions {
    padding: 0 30px 30px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }

  .notice-footer {
    height: 60px;
    padding: 21px 30px;
    border-radius: 0 0 $border-radius-large $border-radius-large;
    background-color: $bg-color;
    line-height: 18px;
    text-align: center;
  }
}

.rate-modal {
  .rate-header {
    height: 60px;
    padding: 20px 30px;
    background-color: $k-green-background;
    color: $k-green;

    .new-icon {
      fill: $k-green;
      vertical-align: middle;
    }

    .rate-header-text {
      vertical-align: middle;
    }
  }

  .notice-regular-text {
    font-size: 13px;

    .rate-name {
      font-size: 16px;
    }
  }
}

.send-message-modal {
  .recipients-list-item {
    padding: 20px 0;
    border-top: 1px solid $k-light-gray;

    @media (min-width: $screen-sm-min) {
      padding: 20px 30px;
    }
  }

  .send-message-modal-inner {
    min-width: 298px;
    padding: 30px;
  }

  .expandable-input {
    height: 400px;

    textarea {
      height: 100%;
    }
  }

  &.update-modal {
    .flex-right {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 30px 10px;

      a {
        font-size: 13px;
        cursor: pointer;
      }
    }

    .lawyer {
      text-transform: capitalize;

      &.italic {
        font-style: italic;
      }

      &.notified {
        text-decoration: line-through;
      }

      &.no-link {
        text-decoration: none;
        cursor: default;
      }

      &.lawyer-lead {
        font-style: italic;
        font-weight: 400;
      }

      &.sent {
        color: $k-green;
      }

      &.unsent {
        color: $orange;
      }

      &.applied {
        color: $k-purple;
      }

      &.invited {
        color: $k-blue;
      }

      &.tracked {
        color: $darker-gray;
      }

      &.lawyer-rejected, &.client-rejected, &.retired, &.declined {
        color: $k-red;
      }
    }
  }
}

.rejection-modal {
  .error-container {
    color: $error-red;
    font-weight: 400;

    .icon-container {
      width: 20%;
      float: left;

      @media (min-width: $screen-sm-min) {
        width: 10%;
      }
    }

    .error-text {
      width: 80%;
      float: left;

      @media (min-width: $screen-sm-min) {
        width: 90%;
      }
    }

    .base-icon {
      width: 20px;
      height: 20px;
      margin-top: 3px;
      margin-right: 10px;
      fill: $error-red;
    }
  }
}

.appointment-modal {
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    border-bottom: 1px solid $k-light-gray;
    border-radius: $border-radius-large $border-radius-large 0 0;
    background-color: $bg-color;
    font-size: 24px;
    text-align: center;

    @media (min-width: $screen-sm-min) {
      padding: 40px 50px;
    }
  }

  .appointment-container {
    padding: 20px 30px;

    @media (min-width: $screen-sm-min) {
      padding: 30px 50px;
    }
  }
}
