.editing-tabs {
  margin-right: 0;
  margin-left: 0;

  .editing-tab {
    height: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $k-light-gray;
    color: $k-dark-gray;
    font-size: 11px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:first-child {
      border-top-left-radius: $border-radius-large;
      border-top-right-radius: $border-radius-large;
    }

    @media (min-width: $screen-sm-min) {
      height: 70px;
      padding-top: 21px;
      padding-bottom: 21px;
      line-height: 14px;

      &:first-child {
        border-top-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: $border-radius-large;
      }
    }

    @media (min-width: $screen-md-min) {
      height: 80px;
      padding-top: 30px;
      padding-bottom: 30px;
      line-height: 20px;
    }

    @media (min-width: $screen-lg-min) {
      height: 90px;
      padding-top: 35px;
      padding-bottom: 35px;
    }

    &:hover, &:focus {
      outline: none;
      background-color: $bg-color;
      color: $k-darker-gray;
      cursor: pointer;
    }

    &.selected-step {
      border-bottom: 1px solid transparent;
      background-image: linear-gradient(45deg, $k-blue, $k-blue);
      background-repeat: no-repeat;
      background-position: 0 calc(100% + 1px);
      background-size: 100% 3px;
      color: $k-darker-gray;
    }

    &.invalid-step {
      color: $error-red;

      &:hover, &.selected-step {
        color: $k-darker-gray;
      }
    }
  }
}

.scrolling-panel {
  height: 400px;
  padding: 30px;
  overflow: auto;
  border: 1px solid $k-light-gray;
  border-radius: $border-radius-large;
  @include hide-scrollbar;
}
