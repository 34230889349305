.navbar {
  margin-bottom: 0;
  border: 0;
  background-color: $white;
  @include k-shadow;

  &.sidenav-topnav {
    @media (min-width: $screen-md-min) {
      min-height: auto;

      .navbar-header {
        display: none;
      }
    }
  }

  &.primary-marketplace-gradient .brand-icon svg path {
    fill: $white;
  }

  &.gradient-nav {
    background: $night-blue;
    background: linear-gradient(90deg, $night-blue, $purple-5);
    overflow: hidden;

    .brand-icon {
      fill: $white;
    }
  }

  .navbar-header {
    .navbar-brand {
      height: 80px;
      padding: 25px 30px;
    }

    .navbar-toggle {
      margin: 29px 30px 29px 0;
      padding: 6px;

      .icon-bar {
        background-color: $black;
      }
    }
  }

  .navbar-nav {
    padding-right: 15px;

    @media (min-width: $screen-sm-min) {
      padding-right: 0;
    }

    &.navbar-right {
      padding-right: 15px;

      @media (min-width: $screen-sm-min) {
        padding-right: 30px;
      }
    }

    > li {
      &:hover, &:active, &:focus {
        outline: none;
        background-color: rgba($white, .1);

        > a {
          background-color: transparent;
          @include opacity(.75);
        }

        @media (min-width: $screen-sm-min) {
          .dropdown-menu {
            display: block;
          }
        }
      }

      &.dropdown .dropdown-toggle {
        font-weight: 700;
        text-transform: uppercase;
      }

      @media (min-width: $screen-sm-min) {
        &.current {
          border-top: 4px solid rgba($white, .75);

          > a {
            margin-top: -4px;
          }
        }
      }

      > a, > .purechat-button-expand {
        padding: 10px 15px;
        color: $white;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;

        @media (min-width: $screen-sm-min) {
          padding: 30px 8px;
        }

        @media (min-width: $screen-md-min) {
          padding: 30px 20px;
        }

        &:hover, &:focus {
          background-color: transparent;
        }

        .nav-icon {
          margin-right: 15px;
          fill: $white;
        }
      }
    }

    &:not(.navbar-right) > li > a {
      font-weight: 700;
      text-transform: uppercase;
    }

    .dropdown-menu {
      padding: 0;
      border: 0;
      border-bottom-right-radius: $border-radius-large;
      border-bottom-left-radius: $border-radius-large;
      background-color: $white;
      @include k-shadow;

      li {
        padding: 0;
        border-top: 1px solid $k-lighter-gray;

        &:first-child {
          border-top: 0;
        }

        &:last-child {
          border-bottom-right-radius: $border-radius-large;
          border-bottom-left-radius: $border-radius-large;
        }

        &:hover {
          background-color: $bg-color;
        }

        a {
          padding: 20px;
          color: $k-darker-gray;
          font-size: 13px;
          font-weight: 600;
          line-height: 1.5;

          &:hover {
            background-color: transparent;
          }
        }
      }

      .nav-icon {
        margin-right: 20px;
        fill: $k-darker-gray;
      }
    }
  }

  &.admin-nav {
    background-color: $k-darker-gray;
    box-shadow: none;

    .brand-icon {
      fill: $white;
    }

    .navbar-header .navbar-toggle .icon-bar {
      background-color: $white;
    }

    .navbar-nav {
      > li:hover > a {
        @include opacity(1);
      }

      .dropdown-menu {
        background-color: $k-darker-gray;

        li:hover {
          background-color: rgba($white, .1);
          cursor: pointer;
        }

        li a {
          color: $white;
        }
      }

      .nav-icon {
        fill: $white;
      }
    }
  }
}

.navbar.signed-out-nav {
  margin-bottom: 0;
  border-bottom: 1px solid $k-gray;
  background-color: $white;
  box-shadow: none;

  .nav-container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .navbar-header {
    .navbar-brand {
      height: calc($nav-height - 1px);
      padding: 28px 16px 27px;

      .brand-icon {
        display: inline-block;
        width: 120px;
        height: 24px;
        vertical-align: middle;
        fill: $k-darker-gray;
      }
    }
  }
}

.purechat-button-expand {
  border: 0;
  background-color: transparent;
  font-size: 13px;
  line-height: 20px;
  appearance: none;
}

.logout-container form {
  display: none;
}

.padding-because-fixed-nav {
  padding-top: $nav-height;
}

.nav-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: text-top;
}

.slidenav {
  &, .slidenav-sub {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    padding: 0;
    overflow-x: hidden;
    transition: .3s;
    background: $bg-color;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    display: block;
    padding: 22px 20px;
    border-bottom: 1px solid $k-light-gray;
    color: $body-text-color;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    text-decoration: none;
    @include text-ellipsis;

    .nav-icon {
      width: 16px;
      height: 16px;
      margin-right: 15px;
      fill: $body-text-color;
      vertical-align: top;
    }
  }

  .slidenav-sub-forward, .slidenav-sub-back {
    background: $k-lighter-gray;
  }

  .slidenav-sub a {
    font-weight: 400;

    &.slidenav-sub-back {
      font-weight: 600;
    }
  }
}

.slidenav-overlay {
  position: fixed;
  z-index: 1040;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity .3s;
  opacity: 0;
  background-color: rgba($black, .9);
}

.sidenav-nav {
  display: none;
  width: 20%;
  color: $white;
  font-size: 13px;
  vertical-align: top;

  @media (min-width: $screen-md-min) {
    display: table-cell;
  }

  &.dark {
    width: 162px;
    background-color: $k-darker-gray;

    .sidenav-options .primary {
      a, .nav-action {
        margin: 0 20px;
      }

      &.expanded > a {
        padding-bottom: 10px;
      }

      .expand-container a {
        padding: 5px 0;
        color: rgba($white, .8);

        &:hover {
          color: rgba($white, .5);
        }
      }
    }

    .sidenav-options .secondary {
      margin: 0 20px;

      a, .nav-action {
        padding: 5px 0;
      }
    }
  }

  .sidenav-cell {
    height: 80px;
    padding: 22px;
    text-align: center;

    .priori-icon {
      display: inline-block;
      width: 36px;
      height: 36px;
      fill: $white;
    }
  }

  .sidenav-options {
    border-top: 1px solid rgba($white, .15);
    font-weight: 700;

    a, .nav-action {
      display: block;
      color: $white;
      line-height: 20px;

      &:hover {
        color: rgba($white, .5);

        .nav-icon {
          fill: rgba($white, .5);
        }
      }

      &:hover, &:focus {
        text-decoration: none;
      }
    }

    .primary {
      text-transform: uppercase;

      a, .nav-action {
        margin: 0 25px;
        padding: 20px 0;
        border-bottom: 1px solid rgba($white, .15);

        @media (min-width: $screen-lg-min) {
          margin: 0 40px;
        }
      }

      &.current, &.current a, &.current a:hover {
        background-color: $bg-color;
        color: $body-text-color;

        .nav-icon {
          fill: $body-text-color;
        }
      }

      &.expanded {
        > a {
          border-bottom: 0;
        }

        .expand-container {
          display: block;
        }
      }

      .expand-container {
        display: none;
        margin: 0 25px;
        border-bottom: 1px solid rgba($white, .15);

        @media (min-width: $screen-lg-min) {
          margin: 0 40px;
        }

        a, .nav-action {
          display: block;
          margin: 0;
          padding: 10px 0 10px 38px;
          border-bottom: 0;
          font-weight: 400;
          text-transform: none;
          user-select: none;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 20px;
          }

          &.current {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
    }

    .secondary {
      margin: 0 25px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba($white, .15);

      @media (min-width: $screen-lg-min) {
        margin: 0 40px;
      }

      .secondary-header {
        padding: 20px 0 5px;
        text-transform: uppercase;
      }

      a {
        padding: 5px 0 5px 38px;
        color: rgba($white, .8);
        font-weight: 400;

        &:hover {
          color: rgba($white, .5);
        }

        &.current {
          color: $white;
          font-weight: 600;
          text-decoration: underline;

          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .nav-icon {
    margin-right: 20px;
    fill: $white;
  }

  .button-container {
    padding: 30px 25px;

    @media (min-width: $screen-lg-min) {
      padding: 30px 40px;
    }
  }
}
