.fade-scroll-container {
  position: relative;

  &.scrolled-up::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, rgba($white, 0) 0%, rgba($white, .75) 100%);
    pointer-events: none;
  }

  &.scrolled-down::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, rgba($white, .75) 0%, rgba($white, 0) 100%);
    pointer-events: none;
  }

  .fade-scrollable {
    // NOTE: set max-height to an appropriate value where this is used
    max-height: 400px;
    overflow-y: auto;
    @include hide-scrollbar;
  }
}
